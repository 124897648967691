import $ from 'jquery';

class Header {
	// Theme values
	readonly scrolledClass = 'scroll';
	readonly switchOffset = 72;

	navBar: HTMLElement;

	constructor() {
		this.onScroll = this.onScroll.bind(this);

		if (module.hot) {
			module.hot.dispose(() => {
				window.removeEventListener('scroll', this.onScroll);
			});
		}

		const header = document.getElementById('header')!;
		this.navBar = header.querySelector('.navbar')!;

		this.onScroll();
		window.addEventListener('scroll', this.onScroll, { passive: true });
	}

	onScroll(): void {
		const hasScrolled = window.scrollY > this.switchOffset;
		const hasClass = this.navBar.classList.contains(this.scrolledClass);

		if (hasScrolled && !hasClass) {
			this.navBar.classList.add(this.scrolledClass);
		} else if (!hasScrolled && hasClass) {
			this.navBar.classList.remove(this.scrolledClass);
		}
	}
}

$(() => new Header());
